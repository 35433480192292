import clsx from 'clsx';
import { PATH_CONSTANT } from 'config/path.constant';
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import pjson from '../../../package.json';
import metaApp from '../../metadata.json';

export const SidebarComponent = () => {
  const location = useLocation();
  const { pathname } = location;
  const [menutItems] = useState([
    {
      id: 'menu-users',
      title: 'User',
      icon: 'icon-ico-users',
      parentPath: '/users',
      isOpen: false,
      children: [
        {
          id: 'menu-users-list',
          title: 'User List',
          basePath: '/users/list',
          icon: null,
          link: PATH_CONSTANT.USER.USER_LIST,
        },
        {
          id: 'menu-users-create',
          title: 'Create User',
          icon: null,
          link: PATH_CONSTANT.USER.USER_CREATE,
        },
      ],
    },
    {
      id: 'schedules',
      title: 'Jadwal & Presensi',
      icon: 'icon-watch',
      parentPath: '/schedule',
      isOpen: false,
      children: [
        {
          id: 'sechdule-presensi',
          title: 'Presensi',
          basePath: '/schedule/presensi',
          icon: null,
          link: PATH_CONSTANT.PRESENSI.LIST,
        },
        {
          id: 'sechdule-overtime',
          title: 'Lembur',
          basePath: '/schedule/overtime',
          icon: null,
          link: PATH_CONSTANT.OVERTIME.LIST,
        },
        {
          id: 'sechdule-presence',
          title: 'Jadwal',
          basePath: '/schedule/schedule',
          icon: null,
          link: PATH_CONSTANT.SCHEDULE.LIST,
        },
        {
          id: 'sechdule-req-change-schedule',
          title: 'Req. Ganti Jadwal',
          basePath: '/schedule/ganti-jadwal',
          icon: null,
          link: PATH_CONSTANT.GANTI_JADWAL.LIST,
        },
      ],
    },
    {
      id: 'report-ba',
      title: 'Report BA',
      icon: 'icon-ico-report',
      parentPath: '/report-ba',
      isOpen: false,
      children: [
        {
          id: 'report-ba-sales',
          title: 'Sales',
          basePath: '/report-ba/sales',
          icon: null,
          link: PATH_CONSTANT.REPORT_BA.SALES,
        },
        {
          id: 'report-ba-visitor',
          title: 'Visitor',
          basePath: '/report-ba/visitor',
          icon: null,
          link: PATH_CONSTANT.REPORT_BA.VISITOR,
        },
        {
          id: 'report-ba-ned-product',
          title: 'NED Product',
          basePath: '/report-ba/ned-product',
          icon: null,
          link: PATH_CONSTANT.REPORT_BA.NED_PRODUCT,
        },
        {
          id: 'report-ba-cycle-count',
          title: 'Cycle Count',
          basePath: '/report-ba/cycle-count',
          icon: null,
          link: PATH_CONSTANT.REPORT_BA.CYCLE_COUNT,
        },
      ],
    },
    {
      id: 'stock-movement',
      title: 'Stock Movement',
      icon: 'icon-ico-stock',
      parentPath: '/stock-movement',
      isOpen: false,
      children: [
        {
          id: 'stock-movement-po-list',
          title: 'PO List',
          basePath: '/stock-movement/pre-order',
          icon: null,
          link: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
        },
        {
          id: 'stock-movement-approvement-list',
          title: 'Approvement',
          basePath: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_LIST,
          icon: null,
          link: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_LIST,
        },
        {
          id: 'stock-movement-picking-list',
          title: 'Picking',
          basePath: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_LIST,
          icon: null,
          link: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_LIST,
        },
        {
          id: 'stock-movement-packing-list',
          title: 'Packing',
          basePath: PATH_CONSTANT.STOCK_MOVEMENT.PACKING_LIST,
          icon: null,
          link: PATH_CONSTANT.STOCK_MOVEMENT.PACKING_LIST,
        },
        {
          id: 'stock-movement-outbond-list',
          title: 'Outbond',
          basePath: PATH_CONSTANT.STOCK_MOVEMENT.OUTBOND_LIST,
          icon: null,
          link: PATH_CONSTANT.STOCK_MOVEMENT.OUTBOND_LIST,
        },
      ],
    },
    {
      id: 'menu-store',
      title: 'Store',
      icon: 'icon-ico-store',
      parentPath: '/store',
      isOpen: false,
      children: [
        {
          id: 'menu-store-list',
          title: 'Store List',
          basePath: '/store/list',
          icon: null,
          link: PATH_CONSTANT.STORE.STORE_LIST,
        },
        {
          id: 'menu-store-create',
          title: 'Create Store',
          icon: null,
          link: PATH_CONSTANT.STORE.STORE_CREATE,
        },
      ],
    },

    {
      id: 'menu-product',
      title: 'Product',
      icon: 'icon-ico-product',
      parentPath: '/product',
      isOpen: false,
      children: [
        {
          id: 'menu-product-list',
          title: 'Product List',
          basePath: '/product/list',
          icon: null,
          link: PATH_CONSTANT.PRODUCT.PRODUCT_LIST,
        },
        {
          id: 'menu-product-stock-store-list',
          title: 'Stock In Store',
          basePath: '/product/stock-store/list',
          icon: null,
          link: PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_LIST,
        },
      ],
    },

    {
      id: 'return-product',
      title: 'Return product',
      icon: 'icon-ico-cube',
      parentPath: '/return-product',
      path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
      isOpen: false,
      children: [],
    },
    {
      id: 'analytics',
      title: 'Analytics',
      icon: 'icon-ico-analytic',
      parentPath: '/analytics',
      isOpen: false,
      children: [
        {
          id: 'menu-analytic-sales',
          title: 'Sales',
          basePath: '/analytics/sales',
          icon: null,
          link: PATH_CONSTANT.ANALYTICS.SALES,
        },
        {
          id: 'menu-analytic-product',
          title: 'Product',
          basePath: '/analytics/products',
          icon: null,
          link: PATH_CONSTANT.ANALYTICS.PRODUCT,
        },
        {
          id: 'menu-analytic-traffice',
          title: 'Traffic',
          basePath: '/analytics/traffic',
          icon: null,
          link: PATH_CONSTANT.ANALYTICS.TRAFFIC,
        },
        {
          id: 'menu-analytic-presensi',
          title: 'Presensi',
          basePath: '/analytics/presensi',
          icon: null,
          link: PATH_CONSTANT.ANALYTICS.PRESENSI,
        },
      ],
    },
    {
      id: 'stock-transfer',
      title: 'Stock Transfer',
      icon: 'icon-arrow-up-right',
      parentPath: '/stock-transfer',
      path: PATH_CONSTANT.STOCK_TRANSFER.LIST,
      isOpen: false,
      children: [],
    },
    {
      id: 'share-info',
      title: 'Share Info',
      icon: 'icon-ico-send',
      parentPath: '/share-info',
      isOpen: false,
      children: [
        {
          id: 'share-info-list',
          title: 'List Info',
          basePath: '/share-info/info/',
          icon: null,
          link: PATH_CONSTANT.SHARE_INFO.LIST_INFO,
        },
        {
          id: 'share-info-create',
          title: 'Create Info',
          basePath: '/share-info/create-info',
          icon: null,
          link: PATH_CONSTANT.SHARE_INFO.CREATE_INFO,
        },
        {
          id: 'list-info-skill-develipment',
          title: 'Skill Development',
          basePath: '/share-info/skill-development',
          icon: null,
          link: PATH_CONSTANT.SHARE_INFO.LIST_INFO_SKILL_DEVELOPMENT,
        },
        {
          id: 'create-info-skill-develipment',
          title: 'Create Skill Dev.',
          basePath: PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT,
          icon: null,
          link: PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT,
        },
      ],
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: 'icon-settings',
      parentPath: '/settings',
      isOpen: false,
      children: [
        {
          id: 'setting-mobile-app-versioning',
          title: 'Mobile Version',
          basePath: '/settings/mobile-app-version',
          icon: null,
          link: PATH_CONSTANT.SETTINGS.MOBILE_VERSION,
        },
      ],
    },
  ]);

  return (
    <div className='w-[256px]'>
      <div className='w-[256px] h-full fixed left-0 top-0 bottom-0 side-menu'>
        <Link to='/' className='logo'>
          <img alt='Main Logo' src='/img/logo.svg' className='max-w-max inline-block text-center' />
          <p className='text-ellipsis text-xs mt-6 text-center font-medium overflow-hidden text-green  whitespace-nowrap '>
            App Version: {pjson.version} ({metaApp.build_number})
          </p>
        </Link>

        <div className='inner-menu scroll'>
          {menutItems.map((item) => {
            if (item.path != null && item.children.length === 0) {
              return (
                <Link
                  to={item.path}
                  key={item.id}
                  className={clsx(
                    pathname.includes(item.parentPath)
                      ? 'cursor-pointer flex items-center py-4 px-8 text-white bg-green font-bold text-base transition duration-150 ease-in-out'
                      : 'flex items-center py-4 text-green hover:text-white px-8 hover:bg-green hover:font-bold font-medium text-base transition duration-150 ease-in-out'
                  )}
                >
                  <span className={clsx('text-lg mr-4', item.icon)}></span> {item.title}
                </Link>
              );
            }
            return (
              <>
                <span
                  className={clsx(
                    pathname.includes(item.parentPath)
                      ? 'cursor-pointer flex items-center py-4 px-8 text-white bg-green font-bold text-base transition duration-150 ease-in-out'
                      : 'flex items-center py-4 text-green hover:text-white px-8 hover:bg-green hover:font-bold font-medium text-base transition duration-150 ease-in-out'
                  )}
                  data-bs-toggle='collapse'
                  role='button'
                  href={`#${item.id}`}
                  aria-expanded='true'
                >
                  <span className={clsx('text-lg mr-4', item.icon)}></span> {item.title}
                </span>
                <div className={clsx('collapse', pathname.includes(item.parentPath) && 'show')} id={item.id}>
                  <ul className='pl-0 mt-2 mb-3'>
                    {item.children.map((child) => {
                      return (
                        <Link to={child.link} className='block cursor-pointer relative text-left' key={child.id}>
                          <span
                            className={clsx(
                              'text-green px-8 py-2 block relative hover:menu-active hover:font-bold cursor-pointer  list-menu transition duration-150 ease-in-out text-base ',
                              pathname === child.link && 'menu-active font-bold',
                              pathname.includes(child.basePath) && 'menu-active font-bold'
                            )}
                          >
                            {child.title}
                          </span>
                        </Link>
                      );
                    })}
                  </ul>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
