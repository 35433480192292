import qs from 'query-string';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { isAllPropertyObjectFalsy } from 'ui-utils/object.utils';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { useReportBASalesList } from 'api/report-ba/sales/sales.query.api';
import { catchErrorMessage, convertToRupiah } from 'ui-utils/string.utils';
import { useExportReportSales } from 'api/report-ba/sales/sales.mutation.api';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Report BA',
    path: PATH_CONSTANT.REPORT_BA.SALES,
  },
  {
    title: 'Report Sales',
    path: PATH_CONSTANT.REPORT_BA.SALES,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: null,
  store_ids: [],
};

const STATUS_CLASSNAME = {
  submitted: 'inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-bold',
  approved: 'inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green text-sm font-medium',
};

const SORT_OPTIONS = [
  { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'user_name' },
  { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'user_name' },
  { label: 'Tanggal Penjualan Terbaru - Terdahulu', sortType: 'desc', sortBy: 'report_date' },
  { label: 'Tanggal Penjualan Terdahulu - Terbaru', sortType: 'asc', sortBy: 'report_date' },
];

export const ReportBASalesListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const startDateParams = searchParams.get('startDate') || null;
  const endDateParams = searchParams.get('endDate') || null;
  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'report_date',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    startDate: startDateParams ? moment(startDateParams).toDate() : moment().subtract(1, 'month').toDate(),
    endDate: endDateParams ? moment(endDateParams).toDate() : moment().toDate(),
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });

  const { mutate: exportUser, isLoading: isExporting } = useExportReportSales();

  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useReportBASalesList({
    filter: {
      ...queryFilter,
      startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
      endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
    },
    body: bodyFilter,
  });

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },

      {
        name: 'BA Name & Store',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[30%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <img
                  src={record?.user?.profile_picture || '/img/default-user.jpg'}
                  class='w-12 h-12 object-cover'
                  alt='User'
                />
              </div>
              <Link to={`${PATH_CONSTANT.REPORT_BA.DETAILS}/${record?.id}`}>
                <div class='w-56 pl-3 xl:pl-5'>
                  <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-dark mb-1'>
                    <HighlightedText text={record?.user?.name} highlight={queryFilter?.search} />
                  </div>
                  <div class='text-sm font-semibold text-green mb-1 overflow-hidden block text-ellipsis whitespace-nowrap'>
                    <HighlightedText text={record?.store?.name} highlight={queryFilter?.search} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal Penjualan',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'report_date_formatted',
        render: (record, report_date_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium  text-gray'>{report_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'QTY',
        className: 'w-[6%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record, total_qty_sold) => (
          <div class='w-[6%]'>
            <div class='text-sm font-medium text-center text-gray'>{record?.total_qty_sold.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Total Penjualan',
        className: 'w-[17%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap pl-7',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[17%] pl-7'>
            <div class='text-sm font-semibold text-gray'>{convertToRupiah(record?.total_selling_price)}</div>
          </div>
        ),
      },
      {
        name: 'Last Update',
        className:
          'w-[18%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[18%]'>
            <div class='text-sm font-medium text-gray text-center'>{record?.updated_at_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Status',
        className: 'w-[14%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[14%] flex justify-center'>
            <div class={STATUS_CLASSNAME[record?.status]}>{capitalize(record?.status)}</div>
          </div>
        ),
      },
    ],
    [queryFilter.search]
  );

  const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter);

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportUser(
      {
        queryFilter: {
          ...queryFilter,
          startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
          endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
        },
        bodyFilter: { ...bodyFilter, report_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5 justify-between'>
        <TableSearchInputComponent
          containerClassName='relative w-[27.6%] mr-2'
          placeholder='Cari user atau store...'
          initialValue={INITIAL_VALUE_FILTER_QUERY.search}
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={(value) => {
            setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
            navigate({
              search: qs.stringify({
                ...queryFilter,
                search: value.keyword || undefined,
                page: 1,
              }),
            });
          }}
        />

        <div className='flex xl:justify-start lg:justify-between md:justify-between items-center gap-x-2'>
          <TableButtonSortComponent
            disabled={isLoading}
            onClick={() => {
              setModals((prev) => ({ ...prev, sort: true }));
            }}
          />
          <TableButtonFilterComponent
            disabled={isLoading}
            active={isFilterActive}
            onClick={() => {
              setModals((prev) => ({ ...prev, filter: true }));
            }}
          />
          <TableButtonLimitComponent
            disabled={isLoading}
            value={queryFilter.limit}
            onClick={() => setModals({ ...modals, limit: true })}
          />
          <RangeDatePicker
            disabled={isLoading}
            iconInputClassName='icon-ico-calendar text-xl text-green absolute left-4 top-1'
            inputClassName='mx-1 w-full outline-none text-sm font-semibold text-center border border-gray-1 cursor-pointer  rounded-sm transition hover:border-green duration-150 ease-in-out border-solid py-2 px-3'
            initialStartDate={queryFilter.startDate}
            maxDate={moment().toDate()}
            initialEndDate={queryFilter.endDate}
            adjustDateOnChange={false}
            onChange={({ start, end }) => {
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                }),
              });
              setQueryFilter((prevState) => ({
                ...prevState,
                startDate: start,
                endDate: end,
              }));
            }}
          />
          <TableButtonExport
            disabled={isLoading}
            loading={isExporting}
            onClick={() => {
              setModals({ ...modals, export: true });
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalResult
        description='Update status user berhasil dilakukan!'
        title='Update Status User'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={{
          title: 'Status',
          key: 'status',
          type: 'checkbox',
          multiple: false,
          searchFilter: false,
          options: [
            {
              label: 'Approved',
              value: 'approved',
            },
            {
              label: 'Submitted',
              value: 'submitted',
            },
          ],
        }}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
