import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useState } from 'react';
import { convertToTitleCase } from 'ui-utils/string.utils';

export function Select({
  onChange,
  disabled,
  onBlur,
  showListOnChange = true,
  value,
  name,
  withNativeDownIcon = false,
  options,
  placeholder,
  showOnClick = true,
  error,
  label,
  labelClassName = 'text-sm text-gray font-semibold mb-3',
  containerClassName,
  inputParentClassName = 'relative ',
  nativeSelect = false,
  inputClassName = ' disabled:opacity-50 appearance-none block w-full cursor-pointer px-3 py-2 text-sm font-semibold text-dark bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-1 rounded-sm transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green focus:outline-none  placeholder:font-normal placeholder:text-gray-2',
}) {
  const [searchText, setSearchText] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');
  const [shallowSearchText, setShallowSearchText] = useState('');
  const [showOptions, setShowOptions] = useState(showOnClick ? false : true);

  const filteredOptions = useMemo(() => {
    if (!options) return [];

    if (selectedItemId && !shallowSearchText && showListOnChange) return options;

    return matchSorter(options, searchText, { keys: ['name'] });
  }, [searchText, options, selectedItemId, shallowSearchText, showListOnChange]);

  const handleOnSelectItem = (item) => {
    if (showOnClick) setShowOptions(false);
    setShallowSearchText('');
    setSearchText(item?.name);
    onChange({ target: { name: name, value: item.id } });
  };

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.id === value);
      setSearchText(selectedOption?.name);
      setSelectedItemId(selectedOption?.id);
    }
  }, [value, options]);

  let SelectComponent = (
    <div className={inputParentClassName}>
      <input
        type='text'
        name={name}
        placeholder={placeholder}
        value={searchText}
        onFocus={() => {
          if (showOnClick) setShowOptions(true);
        }}
        onBlur={(e) => {
          setTimeout(() => {
            if (showOnClick) setShowOptions(false);
            onBlur && onBlur(e);
          }, 200);
        }}
        onChange={(event) => {
          setSearchText(event.target.value);
          if (event.target.value === '') {
            onChange({ target: { name: name, value: '' } });
          }
          setShallowSearchText(event.target.value);
        }}
        className={inputClassName}
        disabled={disabled}
      />

      {/* Element to show options list */}
      <div
        className={clsx(
          'max-h-[45vh] scroll z-50 absolute top-10 left-0 w-full bg-white border border-solid border-gray-1 rounded-sm shadow-md',
          showOnClick && !showOptions && 'hidden',
          !showOnClick && !shallowSearchText && 'hidden'
        )}
      >
        {filteredOptions.map((item) => (
          <div
            className={clsx(
              'py-2 px-3  cursor-pointer text-sm  hover:bg-green hover:text-white',
              selectedItemId === item.id ? 'bg-green text-white' : 'text-gray-2'
            )}
            onClick={() => handleOnSelectItem(item)}
          >
            {convertToTitleCase(item.name)}
          </div>
        ))}
        {filteredOptions.length === 0 && <div className='py-2 px-3 text-sm text-gray-2'>No options found</div>}
      </div>

      {withNativeDownIcon && (
        <div className='absolute top-2 right-3'>
          <span className='icon-ico-arrow-down'></span>
        </div>
      )}
    </div>
  );

  if (!containerClassName) return SelectComponent;
  return (
    <div className={containerClassName}>
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
      {SelectComponent}
      {label && <div className={labelClassName}>{label}</div>}
    </div>
  );
}
