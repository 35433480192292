import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { REPORT_BA_SALES_QUERY } from './sales.query.api';

export function useExportReportSales() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/report/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useApproveReport() {
  const queryClient = useQueryClient();
  return useMutation(({ recordId }) => axios.put(`/api/v1/admin/report/approve/${recordId}`).then((res) => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT]);
      queryClient.invalidateQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT_ACTION_LOG]);
    },
  });
}

export function useExportSalesDetailReport() {
  return useMutation(({ recordId }) => axios.get(`/api/v1/admin/report/export/${recordId}`).then((res) => res.data));
}
