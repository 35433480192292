import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import { forwardRef } from 'react';
import { memo } from 'react';
import moment from 'moment';

export const InputComponent = memo(
  ({
    onBlur,
    element = 'input',
    onChange,
    error,
    name,
    value,
    prefix = undefined,
    numberOnly = false,
    placeholder = 'Placeholder',
    label,
    labelClass = 'text-sm font-semibold text-gray mb-2',
    containerClass = 'form-input mb-3',
    inputClass = 'w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 focus:ring-0 focus:ring-green focus:border-green pl-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out',
    type = 'text',
    excludeDates = [],
    ...props
  }) => {
    if (type === 'month') {
      return (
        <InputMonthComponent
          {...props}
          {...{
            onBlur,
            onChange,
            error,
            name,
            value,
            prefix,
            numberOnly,
            placeholder,
            label,
            labelClass,
            containerClass,
            inputClass,
            type,
            excludeDates,
          }}
        />
      );
    }

    if (type === 'time') {
      return (
        <InputTimeCompoennt
          {...props}
          {...{
            onBlur,
            onChange,
            error,
            name,
            value,
            prefix,
            numberOnly,
            placeholder,
            label,
            labelClass,
            containerClass,
            inputClass,
            type,
          }}
        />
      );
    }

    if (type === 'date')
      return (
        <InputDateComponent
          {...props}
          {...{
            onBlur,
            onChange,
            error,
            name,
            value,
            prefix,
            numberOnly,
            placeholder,
            label,
            labelClass,
            containerClass,
            inputClass,
            type,
          }}
        />
      );

    return (
      <div className={containerClass}>
        {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
        {element === 'input' && (
          <input
            id={name}
            name={name}
            type={type}
            className={clsx(inputClass, error && 'border-red-600')}
            onChange={(e) => {
              if (!numberOnly) {
                onChange(e);
              } else {
                onChange({ target: { name, value: e.target.value.replace(/[^0-9]/g, '') } });
              }
            }}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            {...props}
          />
        )}
        {element === 'textarea' && (
          <textarea
            id={name}
            name={name}
            type={type}
            className={clsx(inputClass, error && 'form-control-error')}
            onChange={(e) => {
              if (!numberOnly) {
                onChange(e);
              } else {
                onChange({ target: { name, value: e.target.value.replace(/[^0-9]/g, '') } });
              }
            }}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            {...props}
          />
        )}

        {label && (
          <label htmlFor={name} className={labelClass}>
            {label}
          </label>
        )}
        {prefix && (
          <div class='absolute top-7 left-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block bg-light-gray text-dark border border-solid border-gray-1'>
            {prefix}
          </div>
        )}
      </div>
    );
  }
);

function InputMonthComponent({
  containerClass,
  error,
  value,
  onChange,
  min,
  max,
  disabled,
  onBlur,
  label,
  name,
  labelClass,
  prefix,
  inputClass,
  placeholder,
  excludeDates = [],
  ...props
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={containerClass} ref={ref}>
      <input onClick={onClick} className={inputClass} value={value} placeholder='MMMM-YYYY' />
      <button
        onClick={onClick}
        class='absolute right-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block'
        data-mdb-toggle='datepicker'
      >
        <span class='icon-ico-calendar text-green text-lg'></span>
      </button>
    </div>
  ));
  return (
    <div className={containerClass}>
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
      <DatePicker
        selected={value ? moment(value, 'yyyy-MM').toDate() : null}
        onChange={(e) => {
          onChange({ target: { name, value: moment(e).format('yyyy-MM') } });
        }}
        name={name}
        onBlur={(e) => {
          onBlur(e);
        }}
        customInput={<CustomInput />}
        dateFormat='MMMM yyyy'
        showMonthYearPicker
        minDate={min ? moment(min).toDate() : null}
        maxDate={max ? moment(max).toDate() : null}
        disabledKeyboardNavigation
        disabled={disabled}
        excludeDates={excludeDates}
        {...props}
      />
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      {prefix && (
        <div class='absolute top-7 left-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block bg-light-gray text-dark border border-solid border-gray-1'>
          {prefix}
        </div>
      )}
    </div>
  );
}

function InputTimeCompoennt({
  containerClass,
  error,
  value,
  onChange,
  min,
  max,
  disabled,
  onBlur,
  label,
  name,
  labelClass,
  prefix,
  inputClass,
  placeholder,
  ...props
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={containerClass} ref={ref}>
      <input onClick={onClick} className={inputClass} value={value} placeholder='HH:mm' />
      <button
        onClick={onClick}
        class='absolute right-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block'
        data-mdb-toggle='datepicker'
      >
        <span class='icon-ico-clock text-green text-lg'></span>
      </button>
    </div>
  ));
  return (
    <div className={containerClass}>
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
      <DatePicker
        selected={value ? moment(value, 'HH:mm').toDate() : null}
        onChange={(e) => {
          onChange({ target: { name, value: moment(e).format('HH:mm') } });
        }}
        name={name}
        onBlur={(e) => {
          onBlur(e);
        }}
        timeIntervals={15}
        timeCaption='Select Time'
        dateFormat='HH:mm'
        timeFormat='HH:mm'
        customInput={<CustomInput />}
        showTimeSelect
        showTimeSelectOnly
        disabled={disabled}
        {...props}
      />
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      {prefix && (
        <div class='absolute top-7 left-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block bg-light-gray text-dark border border-solid border-gray-1'>
          {prefix}
        </div>
      )}
    </div>
  );
}

function InputDateComponent({
  containerClass,
  error,
  value,
  onChange,
  min,
  max,
  disabled,
  onBlur,
  label,
  name,
  labelClass,
  prefix,
  inputClass,
  placeholder,
  ...props
}) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={containerClass} ref={ref}>
      <input onClick={onClick} className={inputClass} value={value} placeholder='dd/MM/yyyy' />
      <button
        onClick={onClick}
        class='absolute right-0 rounded-tl-sm rounded-bl-sm py-2.5 px-3 text-sm inline-block'
        data-mdb-toggle='datepicker'
      >
        <span class='icon-ico-calendar text-green text-lg'></span>
      </button>
    </div>
  ));
  return (
    <div className={containerClass}>
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
      <DatePicker
        selected={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
        onChange={(e) => {
          onChange({ target: { name, value: moment(e).format('YYYY-MM-DD') } });
        }}
        name={name}
        minDate={min ? moment(min).toDate() : null}
        maxDate={max ? moment(max).toDate() : null}
        onBlur={(e) => {
          onBlur(e);
        }}
        customInput={<CustomInput />}
        dateFormat='dd/MM/yyyy'
        disabledKeyboardNavigation
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        placeholderText={placeholder}
        disabled={disabled}
        {...props}
      />
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
    </div>
  );
}

export function InputPasswordComponent({
  onBlur,
  onChange,
  onToggleShow = () => {},
  showPassword,
  error,
  name,
  value,
  placeholder,
  label,
  labelClass = 'text-sm font-semibold text-gray mb-2',
  containerClass = 'form-input mb-3 ',
  inputClass = 'w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pr-16 pl-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:ring-0 focus:ring-green focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out',
}) {
  return (
    <div className={containerClass}>
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
      <div className='relative'>
        <input
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          className={clsx(inputClass, error && 'form-control-error')}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
        />
        <div
          className='cursor-pointer text-lg text-green absolute right-3 top-2.5'
          onClick={() => onToggleShow(!showPassword)}
        >
          <span className={clsx(showPassword ? 'icon-ico-eye-close' : 'icon-ico-eye')}></span>
        </div>
      </div>
      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
}
