import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { useGetChangeScheduleRequestDetails } from 'api/schedules/req-ganti-jadwal/change-schedule.query.api';
import { Select } from 'components/form/select.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useUpdateScheduleRequests } from 'api/schedules/req-ganti-jadwal/change-schedule.mutation.api';
import { toast } from 'react-toastify';
import { isURLImage } from 'ui-utils/object.utils';
import { FileAttachmentComponent } from 'components/attachment/file-attacment.component';
import { catchErrorMessage } from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.GANTI_JADWAL.LIST,
  },
  {
    title: 'Request Ganti Jadwal',
    path: PATH_CONSTANT.GANTI_JADWAL.LIST,
  },
  {
    title: 'Detail Request',
    path: PATH_CONSTANT.GANTI_JADWAL.LIST,
  },
];

const STATUS_CLASSNAME = {
  REJECTED: 'inline-block bg-red-status text-center py-1 px-1 rounded-sm text-red-1 text-sm font-medium',
  APPROVED: 'inline-block bg-green-status text-center  py-1 px-1 rounded-sm text-green text-sm font-medium',
  WAITING_APPROVEMENT: 'inline-block bg-yellow-light text-center  py-1 px-1 rounded-sm text-yellow text-sm font-medium',
};

const STATUS_LABEL = {
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  WAITING_APPROVEMENT: 'Waiting Approvement',
};

const APPROVE_OPTIONS = [
  {
    id: 'approved',
    name: 'Approve',
  },
  {
    id: 'rejected',
    name: 'Reject',
  },
];

export const ReqGantiJadwalDetailsComponent = () => {
  const { id } = useParams();

  const [modalConfirmUpdateStatus, setModalConfirmUpdateStatus] = useState({
    open: false,
    scheduleId: null,
  });

  const [selectedAction, setSelectedAction] = useState(null);

  const { data: attendanceDetails, isLoading, isSuccess, isError, error } = useGetChangeScheduleRequestDetails(id);

  const { mutate: changeSchedule, isLoading: isSubmitting } = useUpdateScheduleRequests();

  const handleOnUpdateSchedule = () => {
    changeSchedule(
      {
        schedule_change_request_ids: [id],
        status: selectedAction,
      },
      {
        onSuccess: () => {
          setModalConfirmUpdateStatus({
            open: false,
            scheduleId: null,
          });
          toast.success('Success update this schedule!');
        },
      }
    );
  };

  const handleOnChangeAction = (e) => {
    setSelectedAction(e.target.value);
  };

  useEffect(() => {
    if (
      attendanceDetails?.data?.requestDetail?.status &&
      attendanceDetails?.data?.requestDetail?.status !== 'waiting_approvement'
    ) {
      setSelectedAction(attendanceDetails?.data?.requestDetail?.status);
    }
  }, [attendanceDetails]);

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <>
        {isLoading && (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        )}

        {isSuccess && (
          <>
            <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
              <div class='w-[200px]'>
                <StandartImageComponent
                  withZoom
                  src={attendanceDetails?.data?.userInfo?.profile_picture}
                  className='w-40 h-40 rounded-full border-8 border-solid border-green-2 object-cover'
                />
              </div>
              <div class='w-[calc(100%-200px)]'>
                <div class='flex items-center'>
                  <div class='w-1/3 border-r border-solid pr-5 border-green'>
                    <div class='text-2xl font-semibold text-green'>{attendanceDetails?.data?.userInfo?.name}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{attendanceDetails?.data?.userInfo?.email}</div>
                    <div class='text-xl font-semibold text-green'>{attendanceDetails?.data?.userInfo?.store_name}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{attendanceDetails?.data?.userInfo?.city}</div>
                  </div>
                  <div class='w-2/3 pl-28 flex flex-col justify-center items-center'>
                    <div className='flex flex-row items-center w-full justify-start'>
                      <div className='flex flex-row items-center mt-5  w-5/12'>
                        <div className='icon-ico-bookmark text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Tipe Request</p>
                          <p class='text-sm text-gray font-medium mb-2'>
                            {attendanceDetails?.data?.requestDetail?.request_type}
                          </p>
                        </div>
                      </div>
                      <div className='w-2/12 text-center'></div>

                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-info text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Status</p>
                          <p class={STATUS_CLASSNAME?.[attendanceDetails?.data?.requestDetail?.status?.toUpperCase()]}>
                            {STATUS_LABEL?.[attendanceDetails?.data?.requestDetail?.status?.toUpperCase()]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row items-center w-full mt-3 justify-start'>
                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-calendar text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Tanggal Cuti</p>
                          <p class='text-sm text-gray font-medium mb-2'>
                            {attendanceDetails?.data?.requestDetail?.initial_schedule_formatted}
                          </p>
                        </div>
                      </div>
                      <div className='w-2/12 text-center'>
                        <span class='icon-ico-chev-right text-2xl text-center   text-green w-8 h-8 text-cente bg-green-2 rounded-full'></span>
                      </div>
                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-calendar text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Tanggal Pengganti</p>
                          <p class='text-sm text-gray font-medium mb-2'>
                            {attendanceDetails?.data?.requestDetail?.replacement_schedule_formatted}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-row w-full justify-start gap-9 mt-4'>
                      <Select
                        onChange={handleOnChangeAction}
                        name='type'
                        withNativeDownIcon
                        containerClassName=' w-[60%]  relative '
                        placeholder='Select Action'
                        options={APPROVE_OPTIONS}
                        value={selectedAction}
                      />
                      <ButtonSubmit
                        className='bg-green hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7 w-[30%]'
                        title='Apply'
                        disabled={!selectedAction}
                        onClick={() => {
                          setModalConfirmUpdateStatus((prev) => ({
                            ...prev,
                            open: true,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className='h-[70vh] flex w-full items-center text-center'>
                <BounceLoading color='#5E755A' />
              </div>
            ) : (
              <>
                <div className='mb-5'>
                  <div className='flex flex-row justify-between gap-2 mb-5'>
                    <div class='text-2xl font-semibold text-green mr-10 '>Note & Attachment</div>
                  </div>
                  <div className='border border-gray-1 flex flex-row'>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <div className='flex flex-row justify-between gap-10' id='clock-in-info-time'>
                          <div className='w-full'>
                            <div className='text-md text-green  font-medium mb-3'>Notes</div>
                            <div className='text-sm text-gray font-medium mb-3'>
                              {attendanceDetails?.data?.requestDetail?.note}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <div className='flex flex-row justify-between gap-10' id='clock-in-info-time'>
                          <div className='w-full'>
                            <div className='text-md text-green  font-medium mb-3'>Gambar & File Attachment</div>
                            <div className='flex flex-row flex-wrap gap-5 mb-2'>
                              {attendanceDetails?.data?.requestDetail?.attachments
                                .filter((itemUrl) => isURLImage(itemUrl))
                                .map((itemUrl) => (
                                  <StandartImageComponent src={itemUrl} className='h-72 w-72 ' withZoom />
                                ))}
                            </div>
                            <div className='mt-3'>
                              {attendanceDetails?.data?.requestDetail?.attachments
                                .filter((itemUrl) => !isURLImage(itemUrl))
                                ?.map((itemUrl, index) => (
                                  <FileAttachmentComponent url={itemUrl} title={`Attachment ${index + 1}`} />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>

      <ModalConfirmation
        description='Apakah anda yakin ingin mengupdate data request ubah jadwal ini?'
        title='Update Data'
        textConfirm='Update'
        loading={isSubmitting}
        visible={modalConfirmUpdateStatus.open}
        onConfirm={handleOnUpdateSchedule}
        onClose={() =>
          setModalConfirmUpdateStatus({
            open: false,
          })
        }
      />
    </MainDashboardLayoutComponent>
  );
};
